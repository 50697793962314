<template>
  <span>(Inherited from {{ getDefaultLanguage }})</span>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'InheritText',
  data() {
    return {}
  },
  computed: {
    getDefaultLanguage() {
      if (this.languages && this.languages.length) {
        const defaultLanguge = this.languages.find((language) => language.isDefault)
        if (defaultLanguge) {
          const arrayText = defaultLanguge.languageName.split(' ')
          return arrayText[0]
        }
      }
      return ''
    },
    ...mapState({
      languages: (state) => state.languages.languages,
    }),
  },
  methods: {},
}
</script>
